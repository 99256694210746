<script>
export default {
    name: 'shop-item',
    props: {
        itemName: String
    },
    data() {
        return {
            quantityToAdd: 0,
            itemToAdd: {}
        }
    },
    methods: {
        addThisToCart() {
            this.itemToAdd = { itemName: this.itemName, quantityToAdd: this.quantityToAdd };
            this.$emit('addToCart', this.itemToAdd);
        }
    }
}
</script>
<template>
    <div>
        <label>
            <p>{{ itemName }}</p>
            <input type="number" v-model="quantityToAdd" /><button @click.prevent="addThisToCart()">Add</button>
        </label>
    </div>
</template>