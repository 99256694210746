<script>
import supabase from "@/supabase.js";
import { store } from "@/store.js";

export default {
    data() {
        return {
            documents: [],
            loading: { value: false },
            newItem: {
                name: "",
                description: "",
            },
            showCreateDocument: false,
        }
    },
    mounted() {
        this.getDocuments(); // TODO: move this to beforeRouteEnter so there is no snapping.
    },
    methods: {
        async addToInventory() {
            console.log('Add Item Here');
        },

        async getDocuments() {
            if (store.isLoggedIn()) {
                try {
                    this.loading.value = true;
                    let { data, error, status } = await supabase
                        .from("documents")
                        .select(`*, profiles(*), document_drafts(*)`)
                        .eq("document_drafts.archived", false)
                        .order("updated_at", { ascending: false });

                    if (error && status !== 406) throw error

                    if (data) {
                        console.log(data);
                        this.documents = data;
                    }
                } catch (error) {
                    console.log("Could not access DB: ", error.message);

                    if (error.message == "JWSError JWSInvalidSignature") {
                        try {
                            console.log("Attempting log out...");
                            this.loading.value = true
                            let { error } = await supabase.auth.signOut()
                            if (error) throw error
                        } catch (error) {
                            console.log("Issue with logging out: ", error.message)
                        } finally {
                            this.loading.value = false
                            // Todo: add routing here as well.								
                        }
                    }
                } finally {
                    this.loading.value = false
                }
            }
        },
        async addDocument() {
            try {
                this.newItem.updated_at = new Date();
                this.newItem.created_at = new Date();
                this.newItem.owner = store.state.user.id;
                this.newItem.status = 1;
                console.log("Adding Document: ", this.newItem);
                this.loading.value = true

                let { error } = await supabase.from("documents").upsert(this.newItem, {
                    returning: "minimal", // Don't return the value after inserting
                });
                if (error) throw error
                this.getDocuments();
                this.showCreateDocument = false;
                this.newItem = {
                    name: "",
                    description: "",
                }
            } catch (error) {
                alert(error.message);
                console.log("Error with addDocument: ", error.message);
            } finally {
                this.loading.value = false
            }
        },
        async deleteDocument(documentId) {
            try {
                this.loading.value = true
                let { error } = await supabase
                    .from("documents")
                    .delete()
                    .eq("id", documentId);
                if (error) throw error;
                this.getDocuments();
            } catch (error) {
                alert("Error with Delete:", error.message);
                console.log("Error with Delete:", error.message);
            } finally {
                this.loading.value = false
            }
        }
    }
}
</script>
<template>
    <div>
        <h1>Documents Cabinet</h1>
    </div>
    <div class="action-bar">
        <div class="action-bar-left"></div>
        <div class="action-bar-right">
            <button v-if="!showCreateDocument" @click.prevent="showCreateDocument = !showCreateDocument"
                class="btn btn-primary">
                Create Document
            </button>
        </div>
    </div>
    <div v-if="showCreateDocument" class="create-document-wrapper">
        <h3>Start New Document</h3>
        <form class="create-document-form" @submit.prevent="addDocument()">
            <div class="input-item">
                <label class="form-label">Document Name</label>
                <input type="text" v-model="newItem.name" class="form-control" required />
            </div>
            <div class="input-item">
                <label class="form-label">Description</label>
                <input type="text" v-model="newItem.description" class="form-control" required />
            </div>
            <div class="input-item">
                <label class="form-label">Owner</label>
                <input type="text" class="form-control" disabled placeholder="You" />
            </div>
            <div class="input-item">
                <p>You can add the first draft of the document after creating...</p>
            </div>
            <div class="input-item form-actions">
                <button type="submit" class="btn btn-primary">Create Document</button>
                <button type="submit" @click.prevent="showCreateDocument = false"
                    class="btn btn-outline-primary">Close</button>
            </div>
        </form>
    </div>

    <div v-if="!showCreateDocument" class="documents_wrapper">
        <h3>Your Documents</h3>
        <table class="styled-table">
            <thead>
                <tr>
                    <th class="col-name">Name</th>
                    <th class="col-description">Description</th>
                    <th>Drafts</th>
                    <th>Owner</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(document) in documents" :key="document.id" class="document">
                    <td class="col-name">
                        <router-link :to="{ name: 'Document', params: { uuid: document.uuid } }">
                            {{ document.name }}
                        </router-link>
                    </td>
                    <td class="col-description">{{ document.description }}</td>
                    <td>{{ document.document_drafts.length }}</td>
                    <td>{{ document.profiles.username }}</td>
                    <td>{{ document.status }}</td>
                    <td>{{ document.created_at.slice(0, 19).replace('T', ' ') }}</td>
                    <td>{{ document.updated_at.slice(0, 19).replace('T', ' ') }}</td>
                    <td><span v-if="document.document_drafts.length == 0" class="action-trigger"
                            @click.prevent="deleteDocument(document.id)">Delete</span></td>
                </tr>
            </tbody>
        </table>
        <div v-if="documents.length === 0" class="no-items-notice">
            <p>No documents found.</p>
            <button v-if="!showCreateDocument" @click.prevent="showCreateDocument = !showCreateDocument"
                class="btn btn-primary">
                Create New Document
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.create-document-wrapper {
    background-color: #eee;
    // a nice subtle drop shadow
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    padding: 1rem;
}

.create-document-form {
    .input-item {
        margin-bottom: 1.5rem;
    }

    .form-actions {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.styled-table {
    border-collapse: collapse;
    margin: 5px 0px;
    padding: 5px;
    font-family: sans-serif;

    th,
    td {
        padding: 16px 16px;
    }

    thead {
        font-weight: normal;
    }

    tbody {
        font-size: 15px;
    }

    .col-name {
        min-width: 250px;
    }

    td.col-name {
        font-size: 18px;
    }

    .col-description {
        max-width: 450px;
    }

    tr {
        border-bottom: 1px solid #dddddd;
    }
}

.documents_wrapper {
    max-width: 1200px;
    margin: auto;
}

.create-document-wrapper {
    max-width: 800px;
    margin: auto;
}
</style>