<script>
import { store } from "@/store.js";
import supabase from "@/supabase.js"

export default {
    data() {
        return {
            userEmail: ""
        }
    },
    computed: {
        userIsAuthenticated() {
            return store.state?.user?.email ? true : false;
        }
    },
    mounted() {
        this.isLoggedInCheck();
    },
    methods: {
        async isLoggedInCheck() {
            const { data, error } = await supabase.auth.getSession();

            if (error) {
                console.error("Session Error: ", error.message);
            }

            store.state.user = data?.session?.user ? data.session.user : null;

            console.log("User is Authenticated: ", this.userIsAuthenticated, store.state.user);
            if (this.userIsAuthenticated) {
                this.userEmail = store.state.user.email;
            }
        }
    }
}
</script>
<template>
    <div id="nav">
        <div v-if="userIsAuthenticated" id="links">
            <router-link :to="{ name: 'Home' }">Home</router-link>
            <router-link :to="{ name: 'Documents' }">Documents Cabinet</router-link>
            <router-link :to="{ name: 'Diff' }">File Change Viewer</router-link>
            <router-link :to="{ name: 'About' }">About</router-link>
        </div>
        <div id="profile">
            <router-link v-if="userIsAuthenticated" :to="{ name: 'Profile' }">{{ userEmail }}</router-link>
            <router-link v-else :to="{ name: 'Auth' }">Login</router-link>
        </div>
    </div>
    <div id="body">
        <router-view />
    </div>
</template>
<style lang="scss" scoped>
#nav {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    margin: 0px;
    margin-bottom: 30px;

    /* fallback for old browsers */
    background: #c94b4b;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #4b134f, #c94b4b);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #4b134f, #c94b4b);

    #links {
        a {
            display: inline-block;
            margin-right: 30px;
        }
    }

    a {
        color: white;
        font-size: 16px;
        text-decoration: none;
    }
}

#body {
    padding: 0px 20px;
    margin: 0px;
}
</style>