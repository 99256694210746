<script>
import axios from 'axios';

export default {
    data() {
        return {
            isDraggingLeft: false,
            isDraggingRight: false,
            leftFile: null,
            leftInvalid: false,
            leftFileType: "",
            rightFile: null,
            rightInvalid: false,
            rightFileType: "",
            authToken: "cf56ee67720bbfe761f9d794e15fed36",
            accountId: "Nkxihv-test",
            viewerUrlBase: "https://api.draftable.com/v1/comparisons/viewer",
            viewerUrlQuery: "?wait",
            iframeUrl: "",
            uploading: false,
            displayState: "GET_INPUT",
            isDraggingTracker: "",
            fileList: [
                { id: "file0", fileName: "", selected: false, person: "Amy", date: "2023-03-01 20:21" },
                { id: "file1", fileName: "", selected: false, person: "Bonny", date: "2023-01-03 10:54" },
                { id: "file2", fileName: "", selected: false, person: "Charlie", date: "2022-10-01 18:23" },
                { id: "file3", fileName: "", selected: false, person: "David", date: "2022-09-01 03:45" }
            ],
            selectedFiles: []
        };
    },
    computed: {
        canUpload() {
            return this.leftFile && this.rightFile && !this.uploading;
        }
    },
    methods: {
        addDraft() {
            // randomize between five different names
            const names = ["Amy", "Bonny", "Charlie", "David", "Eve"];
            const randomName = names[Math.floor(Math.random() * names.length)];

            const datetime = new Date().toISOString().slice(0, 19).replace('T', ' ');
            this.fileList.unshift({ id: `file${this.fileList.length}`, fileName: "", selected: false, person: `${randomName}`, date: `${datetime}` });
        },
        compareSelected() {
            // Check if there are two files selected.
            if (this.selectedFiles.length !== 2) return;

            // find the files in the list that are selected.
            const leftFileIndex = this.fileList.findIndex((file) => file.id === this.selectedFiles[0].id);
            const rightFileIndex = this.fileList.findIndex((file) => file.id === this.selectedFiles[1].id);

            // Set the files to the left and right.
            this.leftFile = this.fileList[leftFileIndex].file;
            this.leftFileType = this.fileList[leftFileIndex].fileType;
            this.rightFile = this.fileList[rightFileIndex].file;
            this.rightFileType = this.fileList[rightFileIndex].fileType;

            this.handleUpload();

        },
        handleClickList(fileId) {
            // Remove the selected file from the list if it's already selected.
            const fileIndexInSelected = this.selectedFiles.findIndex((file) => file.id === fileId);
            const fileIsSelectedIndex = this.fileList.findIndex((file) => file.id === fileId && file.selected);
            if (fileIsSelectedIndex !== -1) {
                this.selectedFiles.splice(fileIndexInSelected, 1);
                this.fileList[fileIsSelectedIndex].selected = false;
                return;
            }

            // Check length
            if (this.selectedFiles.length >= 2) return;

            // Add the file to the list.
            const fileIndex = this.fileList.findIndex((file) => file.id === fileId);
            // Check there is a file
            if (!this.fileList[fileIndex]?.file) return;
            this.fileList[fileIndex].selected = true;
            this.selectedFiles.push(this.fileList[fileIndex]);
        },
        handleDragEnterList(e) {
            e.preventDefault();
            this.isDraggingTracker = e.target.id;
        },
        handleDragLeaveList(e) {
            e.preventDefault();
            this.isDraggingTracker = "";
        },
        handleDropList(e) {
            e.preventDefault();
            this.isDraggingTracker = "";
            console.log(e.dataTransfer.files[0]);
            try {
                const file = e.dataTransfer.files[0];
                const fileType = this.detectFileType(file);
                const fileIndex = this.fileList.findIndex((file) => file.id === e.target.id);
                this.fileList[fileIndex].fileName = file.name;
                this.fileList[fileIndex].fileType = fileType;
                this.fileList[fileIndex].file = file;
            } catch (error) {
                return;
            }
        },
        handleDragEnter(e) {
            e.preventDefault();
            if (e.target.id === "leftDropArea" || e.target.parentElement.id === "leftDropArea") {
                this.isDraggingLeft = true;
            }
            if (e.target.id === "rightDropArea" || e.target.parentElement.id === "rightDropArea") {
                this.isDraggingRight = true;
            }

        },
        handleDragLeave(e) {
            e.preventDefault();
            if (e.target.id === "leftDropArea" && e.toElement.id === "leftDropArea") {
                this.isDraggingLeft = false;
            }

            if (e.target.id === "rightDropArea" && e.toElement.id === "rightDropArea") {
                this.isDraggingRight = false;
            }
        },
        handleDragOver(e) {
            e.preventDefault();
        },
        handleDropLeft(e) {
            e.preventDefault();
            this.isDraggingLeft = false;
            this.leftInvalid = false;
            console.log(e.dataTransfer.files[0]);

            try {
                this.leftFileType = this.detectFileType(e.dataTransfer.files[0]);
                this.leftFile = e.dataTransfer.files[0];
            } catch (error) {
                this.leftInvalid = true;
                this.leftFileType = "";
                this.leftFile = null;
                return;
            }
        },
        handleDropRight(e) {
            e.preventDefault();
            this.isDraggingRight = false;
            this.rightInvalid = false;
            console.log(e.dataTransfer.files[0]);

            try {
                this.rightFileType = this.detectFileType(e.dataTransfer.files[0]);
                this.rightFile = e.dataTransfer.files[0];
            } catch (error) {
                this.rightInvalid = true;
                this.rightFileType = "";
                this.rightFile = null;
                return;
            }
        },
        detectFileType(file) {
            if (file.type === "application/pdf") {
                return "pdf";
            } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                return "docx";
            } else if (file.type === "text/plain") {
                return "txt";
            } else {
                throw new Error("Unsupported file type");
            }
        },
        async handleUpload() {
            this.uploading = true;
            try {
                const formData = new FormData();
                formData.append('left.file_type', this.leftFileType);
                formData.append('left.file', this.leftFile);
                formData.append('right.file_type', this.rightFileType);
                formData.append('right.file', this.rightFile);
                formData.append('public', true);

                const response = await axios.post('https://api.draftable.com/v1/comparisons', formData, {
                    headers: {
                        Authorization: `Token ${this.authToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log("Response: ", response.data);

                const responseData = response.data;
                if (responseData?.identifier) {
                    this.showViewer(responseData.identifier);
                }
            } catch (error) {
                console.error(error);
                // reset form
                this.resetForm();
            }
        },
        showViewer(comparisonIdentifier) {
            this.iframeUrl = `${this.viewerUrlBase}/${this.accountId}/${comparisonIdentifier}${this.viewerUrlQuery}`;
            console.log("Iframe URL: ", this.iframeUrl);
            this.displayState = "SHOW_RESULTS";
        },
        resetForm() {
            this.isDraggingLeft = false;
            this.isDraggingRight = false;
            this.leftFile = null;
            this.rightFile = null;
            this.leftFileType = "";
            this.rightFileType = "";
            this.uploading = false;
            this.displayState = "GET_INPUT";
        },
    },
};
</script>

<template>
    <h1>Legal Hero | "My NDA" <span class="status-pill">In Progress</span></h1>
    <div class="box-input" v-if="displayState == 'GET_INPUT'">
        <div class="side-bar-wrapper">
            <div class="action-wrapper">
                <h3>Draft History</h3>
                <button class="button upload-button" :disabled="selectedFiles.length != 2" @click="compareSelected">View
                    Changes of Selected</button>
                <button class="button upload-button" @click="addDraft">Add Draft</button>
            </div>
            <div class="side-bar">
                <div v-for="(file, index) in fileList" :key="index" :id="file.id" @dragenter="handleDragEnterList"
                    @dragleave="handleDragLeaveList" @dragover="handleDragOver" @drop="handleDropList"
                    @click.prevent="handleClickList(file.id)"
                    :class="[isDraggingTracker == file.id ? 'drag-over' : '', 'file-item', file.file ? 'file-valid-list' : '', file.selected ? 'file-item-selected' : '']">
                    <img class="drop-area-child icon list-item-child" v-if="file.fileName"
                        src="/icons8-happy-file-64.png" />
                    <span class="drop-area-child list-item-child list-item-file-name" v-if="file.fileName">
                        {{ file.fileName }}
                    </span>
                    <div class="list-item-meta drop-area-child">
                        <span class="drop-area-child">{{ file.person }}</span><span class="drop-area-child">{{ file.date
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-wrapper">
            <div class="round"><img src="/legal-grease-hero.png" /></div>
            <div class="drag-drop-wrapper">
                <div id="leftDropArea" class="drag-drop-area"
                    :class="{ 'drag-over': isDraggingLeft, 'file-valid': leftFile && !leftInvalid }"
                    @dragenter="handleDragEnter" @dragleave="handleDragLeave" @dragover="handleDragOver"
                    @drop="handleDropLeft">
                    <h5 class="drop-area-child">Drop Original File Here</h5>
                    <h2 class="drop-area-child" v-if="leftFile"><img class="drop-area-child"
                            src="/icons8-happy-file-64.png" />
                        {{ leftFile.name }}</h2>

                    <p class="drop-area-child" v-if="leftFileType">Type: {{ leftFileType }}</p>
                    <p class="drop-area-child" v-if="leftInvalid">Invalid File Type</p>
                </div>
                <div id="rightDropArea" class="drag-drop-area"
                    :class="{ 'drag-over': isDraggingRight, 'file-valid': rightFile && !rightInvalid }"
                    @dragenter="handleDragEnter" @dragleave="handleDragLeave" @dragover="handleDragOver"
                    @drop="handleDropRight">
                    <h5 class="drop-area-child">Drop Updated File Here</h5>
                    <h2 class="drop-area-child" v-if="rightFile">
                        <img class="drop-area-child" src="/icons8-happy-file-64.png" />
                        {{ rightFile.name }}
                    </h2>
                    <p class="drop-area-child" v-if="rightFileType">Type: {{ rightFileType }}</p>
                    <p class="drop-area-child" v-if="rightInvalid">Invalid File Type</p>
                </div>
            </div>
            <div class="action-wrapper">
                <button class="button upload-button" :disabled="!canUpload" @click="handleUpload">View Changes</button>
            </div>
        </div>
    </div>
    <div class="results-wrapper" v-if="displayState == 'SHOW_RESULTS'">
        <!-- https://api.draftable.com/v1/comparisons/viewer/Nkxihv-test/StKSjeQQ?wait -->
        <div class="header-with-action">
            <h2>Results</h2>
            <div><button class="button reset-button" @click="resetForm">Close</button></div>
        </div>
        <iframe :src="iframeUrl" style="width: 100%; border: 1px solid #e0e0e0;" allowfullscreen />
        <!-- <iframe src="https://api.draftable.com/v1/comparisons/viewer/Nkxihv-test/StKSjeQQ?wait"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            style="width: 100%; border: 1px solid #e0e0e0;" allowfullscreen /> -->
    </div>
</template>

<style lang="scss" scoped>
.box-input {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    // max-width: 1200px;
    // padding: 0 20px;
}

.side-bar-wrapper {
    flex-grow: 1;
    background-color: #f5f5f5;
    padding: 20px;
    max-width: 25vw;

    .file-item {
        padding: 20px 8px;
        min-height: 60px;
        border: 1px solid #e0e0e0;
        margin-bottom: 10px;

        .list-item-child {
            display: inline-block;
            margin-right: 10px;
        }

        .list-item-file-name {
            font-size: 20px;
        }

        .list-item-meta {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            span {
                color: #888;
                display: inline-block;
            }
        }

        .icon {
            width: 36px;
        }
    }
}

.input-wrapper {
    padding: 0px 20px;
    flex-grow: 3;
    max-width: 75vw;
    height: 90vh;
}

.action-wrapper {
    text-align: center;
    margin-bottom: 20px;
}

.header-with-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.round img {
    display: block;
    margin: 0 auto;
    height: auto;
    width: 400px;
    // margin: 0 0 0 -20%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.drag-drop-wrapper {
    border: 2px solid #ccc;
    padding: 10px;
    text-align: center;
    margin: 20px;
    display: flex;
    justify-content: space-evenly;
}

.drag-drop-area {
    border: 2px dashed #ccc;
    padding: 10px;
    text-align: center;
    margin: 20px;
    cursor: pointer;
    flex-grow: 1;
    min-height: 180px;
    max-width: 45%;
}

.file-valid-list {
    background-color: #FFF;
    cursor: pointer;
}

.file-item-selected {
    background-color: #CCE !important;
}

.button {
    padding: 1px 20px;
    border: none;
    color: white;
    border-radius: 4px;
    font-size: 25px;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.upload-button {
    background-color: #4CAF50;
}

.reset-button {
    background-color: #b31412;
}

.upload-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.drag-drop p {
    margin: 0;
}

.results-wrapper {
    margin: 20px;
    height: 90vh;

    iframe {
        height: 100%;
        width: 100%;
    }
}
</style>
