<script>
export default {
    beforeCreate() {
        console.log("1 beforeCreate");
    },
    created() {
        console.log("2 created");
        this.doSomething();
    },
    beforeMount() {
        console.log("3 beforeMount");
    },
    mounted() {
        console.log("4 Mount");
    },
    beforeUpdate() {
        console.log("5 beforeUpdate");
    },
    updated() {
        console.log("6 updated");
    },
    beforeUnmount() {
        console.log("7 beforeUnmount");
    },
    unmounted() {
        console.log("8 unmounted");
    },
    data() {
        return {
            greeting: "Hello, world!"
        }
    },
    methods: {
        doSomething() {
            console.log('I am here!');
        }
    }
}
</script>

<template>
    <h1>Welcome to Doc Hero!</h1>
    <div class="box center-800">
        <h3>Head over to the <router-link to="Documents">document cabinet</router-link> to get started!</h3>
    </div>
</template>

<style lang="scss" scoped></style>