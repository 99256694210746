import { reactive } from "vue";

export const store = {
    state: reactive({
        user: null
    }),
    isLoggedIn() {
        if (this.state.user !== null) {
            return true;
        } else {
            return false;
        }
    }
}