<script>
import { ref } from "vue"
import supabase from "../supabase"

export default {
    setup() {
        const loading = ref(false);
        const email = ref("");

        const handleLogin = async () => {
            try {
                loading.value = true;
                const { error } = await supabase.auth.signInWithOtp({ email: email.value });
                if (error) throw error;
                alert("Check your email for the login link!");
            } catch (error) {
                if (error instanceof Error) {
                    alert(error.message);
                }
            } finally {
                loading.value = false;
            }
        }
        return {
            loading,
            email,
            handleLogin,
        }
    }
}
</script>

<template>
    <div id="box-profile">

        <form @submit.prevent="handleLogin">
            <div class="form-widget">
                <h1 class="header">Sign In</h1>
                <p class="description">An email containing a sign in link will be sent.</p>
                <div class="input-item">
                    <input class="input-text" type="email" required placeholder="Your email" v-model="email"
                        :disabled="loading" />
                </div>
                <div>
                    <input type="submit" class="btn btn-primary" :value="loading ? 'Loading...' : 'Send Login Link'"
                        :disabled="loading" />
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
// Duplicated in Profile.vue
#box-profile {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
}

form div {
    margin-bottom: 1rem;
}

.input-item {
    width: 100%;
    position: relative;

    label {
        display: block;
        margin-bottom: 0.2rem;
    }

    .input-text {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 18px;
        width: 100%;
    }
}
</style>