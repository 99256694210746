import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import User from "@/views/User.vue";
import Shop from "@/views/Shop.vue";
import NotFound from "@/views/NotFound.vue";
import Auth from "@/views/Auth.vue";
import Profile from "@/views/Profile.vue";
import Diff from "@/views/Diff.vue";
import Documents from "@/views/Documents.vue";
import Document from "@/views/Document.vue";
import supabase from "@/supabase.js";
// import Inventory from "@/views/Inventory.vue";
// import Farm from "@/views/Farm.vue";
//import { store } from "@/store.js";

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
},
{
    path: "/about",
    name: "About",
    component: About,
},
{
    path: "/user/:name",
    name: "User",
    component: User,
    props: true,
},
{
    path: "/shop",
    name: "Shop",
    component: Shop
},
{
    path: "/auth",
    name: "Auth",
    component: Auth
},
{
    path: "/profile",
    name: "Profile",
    component: Profile
},
{
    path: "/documents",
    name: "Documents",
    component: Documents
},
{
    path: "/document/:uuid",
    name: "Document",
    component: Document
},
// {
//     path: "/inventory",
//     name: "Inventory",
//     component: Inventory
// },
// {
//     path: "/farm",
//     name: "Farm",
//     component: Farm
// },
{
    path: "/diff",
    name: "Diff",
    component: Diff
},
{
    path: "/:catchAll(.*)",
    component: NotFound,
}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    try {
        const { data, error } = await supabase.auth.getSession()
        if (error) throw error;

        console.log("Router: session data: ", data);
        console.log("Attempting to going to: ", to.name);

        // User is not logged in. Redirect to Auth.
        if ((typeof data === 'undefined' || !data?.session) && to.name != 'Auth') {
            console.log("You are not logged in. Redirecting to auth page.");
            next({ name: 'Auth' });
        } else if (data?.session && to.name == 'Auth') {
            // User is logged in, trying to get to auth page -> redirect to homepage.
            console.log("You are logged in. Redirecting to home page.");
            next({ name: 'Home' });
        } else {
            console.log("Proceeding to: ", to.name);
            next();
        }
    } catch (error) {
        console.log("Error getting session: ", error.message);
        alert("Error With Login: ", error.message);

        // Error, redirect to auth page.
        console.log("Not Logged In. Redirecting to auth.");
        next({ name: 'Auth' });
    }
});

//router.beforeEach((to, from, next) => {
// if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
// else next()

// if (to.name !== 'Auth' && typeof store.user.username === 'undefined') {
// 	next({ name: 'Auth' });
// }
//})

export default router;