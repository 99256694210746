<!-- https://docs.apryse.com/ -->
<!-- To get this thing to work, must copy viewer files to ./public/webviewer/. See the docs. -->
<!-- Alternative: https://www.syncfusion.com/ -->
<template>
    <div id="webviewer" ref="viewer"></div>
</template>

<script>
import { ref, onMounted } from "vue";
import WebViewer from "@pdftron/webviewer";

export default {
    name: "WebViewer",
    props: { initialDoc: { type: String } },
    setup(props) {
        const viewer = ref(null);
        onMounted(() => {
            console.log("here we go...", props.initialDoc);
            const path = `${process.env.BASE_URL}webviewer`;
            WebViewer({ path, initialDoc: props.initialDoc }, viewer.value)
            // .then(
            //     (instance) => {
            //         const { documentViewer, annotationManager, Annotations } = instance.Core;

            //         documentViewer.addEventListener("documentLoaded", () => {
            //             const rectangleAnnot = new Annotations.RectangleAnnotation({
            //                 PageNumber: 1,
            //                 // values are in page coordinates with (0, 0) in the top left
            //                 X: 100,
            //                 Y: 150,
            //                 Width: 200,
            //                 Height: 50,
            //                 Author: annotationManager.getCurrentUser(),
            //             });
            //             annotationManager.addAnnotation(rectangleAnnot);
            //             annotationManager.redrawAnnotation(rectangleAnnot);
            //         });
            //     }
            // );
            // DEMO: Enable above lines to add a rectangle annotation to the first page of the document
        });
        return {
            viewer,
        };
    },
};
</script>

<style>
#webviewer {
    height: 100%;
}
</style>