<script>
import supabase from "@/supabase.js";
import { store } from "@/store.js";
import WebViewer from "@/components/WebViewer.vue";
import axios from 'axios';

// a function that generates a slug
function generateSlug(fileName) {
    // Remove the file extension if there is one
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    // Replace any spaces or underscores with hyphens
    const hyphenatedName = nameWithoutExtension.replace(/[_\s]+/g, '-');
    // Convert to lowercase
    const slug = hyphenatedName.toLowerCase();
    return slug;
}

// a function that generates a random hex string 6 characters long
function generateRandomHex() {
    return Math.floor(Math.random() * 16777215).toString(16);
}

export default {
    data() {
        return {
            documentWithDrafts: {},
            drafts: [],
            loading: { value: false },
            newDraft: {
                description: ""
            },
            showCreateDraft: false,
            newDraftProcessing: {
                isDragging: false,
                fileInvalid: false,
                fileType: "",
                isUploading: false,
            },
            multiSelectedDrafts: [],
            draftToPreview: null,
            attemptingMultiSelect: false,
            previewDraftUrl: "",
            previewDraftLoading: false,
            authToken: "cf56ee67720bbfe761f9d794e15fed36",
            accountId: "Nkxihv-test",
            viewerUrlBase: "https://api.draftable.com/v1/comparisons/viewer",
            viewerUrlQuery: "?wait",
            iframeUrl: "",
            uploadingToDraftable: false,
        }
    },
    components: {
        WebViewer
    },
    computed: {
        boxMainDisplayState() {
            if (this.draftToPreview && this.previewDraftUrl && !this.previewDraftLoading && !this.attemptingMultiSelect) { return "DRAFT_PREVIEW"; }
            if (this.multiSelectedDrafts.length == 2 && this.iframeUrl && !this.uploadingToDraftable) { return "DIFF_VIEW"; }
            if (this.previewDraftLoading || this.attemptingMultiSelect || this.uploadingToDraftable || this.drafts.length == 0) { return "NOTIFICATIONS"; }
            return "";
        }
    },
    async beforeRouteEnter(to, from, next) {
        try {
            // get from supabase where uuid matches
            let { data: document, error } = await supabase
                .from("documents")
                .select(`*, profiles(*), document_drafts!document_drafts_document_uuid_fkey(*, profiles(*))`)
                .eq("document_drafts.archived", false)
                .eq("uuid", to.params.uuid)
                .order("created_at", { foreignTable: "document_drafts", ascending: false });

            console.log("Got Document from DB (Route Enter): ", document);
            if (error) throw error;
            next((vm) => {
                vm.setData(document[0]);
            });

        } catch (error) {
            console.log("Could not access DB: ", error.message);

            if (error.message == "JWSError JWSInvalidSignature") {
                try {
                    console.log("Attempting log out...");
                    let { error } = await supabase.auth.signOut()
                    if (error) throw error
                } catch (error) {
                    console.log("Issue with logging out: ", error.message)
                }
                // add finally here for route push back to auth. 
            }
        }
    },
    async beforeRouteUpdate(to) {
        try {
            // get from supabase where uuid matches
            let { data: document, error } = await supabase
                .from("documents")
                .select(`*, profiles(*), document_drafts!document_drafts_document_uuid_fkey(*)`)
                .eq("document_drafts.archived", false)
                .eq("uuid", to.params.uuid)
                .order("created_at", { foreignTable: "document_drafts", ascending: false });

            console.log("Got Document from DB (Route Enter): ", document);
            if (error) throw error;

            this.setData(document[0]);
        } catch (error) {
            console.log("Could not access DB: ", error.message);

            if (error.message == "JWSError JWSInvalidSignature") {
                try {
                    console.log("Attempting log out...");
                    let { error } = await supabase.auth.signOut()
                    if (error) throw error
                } catch (error) {
                    console.log("Issue with logging out: ", error.message)
                }
                // add finally here for route push back to auth. 
            }
        }
    },
    mounted() {
        console.log("Mounted: Document");
    },
    methods: {
        async getDrafts() {
            try {
                this.loading.value = true;
                let { data, error, status } = await supabase
                    .from("document_drafts")
                    .select(`*, profiles(*)`)
                    .eq("archived", false)
                    .eq("document_uuid", this.documentWithDrafts.uuid)
                    .order("created_at", { ascending: false });

                if (error && status !== 406) throw error

                if (data) {
                    console.log("Drafts from DB:", data);
                    this.drafts = data;
                }
            } catch (error) {
                console.log("Could not access DB: ", error.message);

                if (error.message == "JWSError JWSInvalidSignature") {
                    try {
                        console.log("Attempting log out...");
                        this.loading.value = true
                        let { error } = await supabase.auth.signOut()
                        if (error) throw error
                    } catch (error) {
                        console.log("Issue with logging out: ", error.message)
                    } finally {
                        this.loading.value = false
                        // Todo: add routing here as well.								
                    }
                }
            } finally {
                this.loading.value = false
            }
        },

        async addNewDraft() {
            try {
                // Upload the file to supabase storage
                this.loading.value = true

                const randHex = generateRandomHex();
                const fileNameSlug = generateSlug(this.newDraftProcessing.file.name) + `-${randHex}.${this.newDraftProcessing.fileType}`;
                const fileLocationInBucket = `${this.documentWithDrafts.uuid}/${fileNameSlug}`;

                const { fileUploadData, fileUploadError } = await supabase
                    .storage
                    .from('documents-01')
                    .upload(fileLocationInBucket, this.newDraftProcessing.file, {
                        cacheControl: '3600',
                        upsert: false,
                        contentType: this.newDraftProcessing.file.type,
                    });

                if (fileUploadError) throw fileUploadError;
                console.log("File Upload Data: ", fileUploadData);

                // Add entry to document_drafts table
                this.newDraft.status = 1;
                this.newDraft.created_at = new Date();
                this.newDraft.created_by = store.state.user.id;
                this.newDraft.document_uuid = this.documentWithDrafts.uuid;
                this.newDraft.file_type = this.newDraftProcessing.fileType;
                this.newDraft.file_mime_type = this.newDraftProcessing.file.type;
                this.newDraft.original_file_name = this.newDraftProcessing.file.name;
                this.newDraft.storage_location = fileLocationInBucket;

                console.log("Adding Document: ", this.newDraft);

                let { error } = await supabase.from("document_drafts").upsert(this.newDraft, {
                    returning: "minimal", // Don't return the value after inserting
                });
                if (error) throw error

                // Refresh and clean up.
                await this.getDrafts();
                this.reset();
                this.showCreateDraft = false;
                this.newDraft = {
                    description: ""
                }
            } catch (error) {
                alert(error.message);
                console.log("Error with addDraft: ", error.message);
            } finally {
                this.loading.value = false
            }
        },
        async deleteDraft(draftUuid) {
            try {
                this.loading.value = true
                // find this draft in the drafts list
                const draftToDelete = this.drafts.find(draft => draft.uuid == draftUuid);

                // Deleted the file first...
                // This will silently fail if the file doesn't exist.
                let { data, errorDeleteStorage } = await supabase
                    .storage
                    .from('documents-01')
                    .remove([draftToDelete.storage_location]);
                console.log("Deleted File: ", data);
                console.log("Deleted File: ", errorDeleteStorage);
                if (errorDeleteStorage) throw new Error(errorDeleteStorage);

                console.log("Attempting delete entry");

                // Then delete the entry in the DB
                const { error } = await supabase
                    .from("document_drafts")
                    .delete()
                    .eq("uuid", draftUuid);
                if (error) throw new Error(error);
            } catch (error) {
                alert("Error with Delete:", error.message);
                console.log("Error with Delete:", error);
            } finally {
                await this.getDrafts();
                this.reset();
                this.loading.value = false
            }
        },
        async archiveDraft(draftUuid) {
            try {
                this.loading.value = true
                // update the archived flag to true
                let { error } = await supabase
                    .from("document_drafts")
                    .update({ archived: true })
                    .eq("uuid", draftUuid);
                if (error) throw error;

                this.getDrafts();
            } catch (error) {
                alert("Error with Delete:", error.message);
                console.log("Error with Delete:", error.message);
            } finally {
                this.loading.value = false
            }
        },
        setData(documentWithDrafts) {
            console.log("Setting Data: ", documentWithDrafts);
            this.documentWithDrafts = documentWithDrafts;
            this.drafts = documentWithDrafts.document_drafts;
            this.multiSelectedDrafts = [];
            if (this.drafts.length > 0) {
                this.drafts[0].selected = true;
                this.draftToPreview = this.drafts[0];
                this.multiSelectedDrafts.push(this.drafts[0]);
                this.previewDraft();
            }
        },
        reset() {
            this.newDraftProcessing = {
                isDragging: false,
                fileInvalid: false,
                fileType: "",
                isUploading: false,
            }
            this.multiSelectedDrafts = [];
            this.previewDraftUrl = "";
            this.draftToPreview = null;
            if (this.drafts.length > 0) {
                this.drafts[0].selected = true;
                this.draftToPreview = this.drafts[0];
                this.multiSelectedDrafts.push(this.drafts[0]);
                this.previewDraft();
            }
        },
        handleDragEnter(e) {
            e.preventDefault();
            if (e.target.id === "new-draft-drop-area" || e.target.parentElement.id === "new-draft-drop-area") {
                this.newDraftProcessing.isDragging = true;
            }
        },
        handleDragLeave(e) {
            e.preventDefault();
            if (e.target.id === "new-draft-drop-area" && e.toElement.id === "new-draft-drop-area") {
                this.newDraftProcessing.isDragging = false;
            }
        },
        handleDragOver(e) {
            e.preventDefault();
        },
        handleDropNewDraft(e) {
            e.preventDefault();
            this.newDraftProcessing.isDragging = false;
            this.newDraftProcessing.fileInvalid = false;
            console.log("File Dropped In: ", e.dataTransfer.files[0]);

            try {
                this.newDraftProcessing.fileType = this.detectFileType(e.dataTransfer.files[0]);
                this.newDraftProcessing.file = e.dataTransfer.files[0];
            } catch (error) {
                this.newDraftProcessing.fileInvalid = true;
                this.newDraftProcessing.fileType = "";
                this.newDraftProcessing.file = null;
                return;
            }
        },
        detectFileType(file) {
            if (file.type === "application/pdf") {
                return "pdf";
            } else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                return "docx";
            } else if (file.type === "text/plain") {
                return "txt";
            } else {
                throw new Error("Unsupported file type");
            }
        },
        closeCreateNewDraftDialog() {
            this.newDraftProcessing = {
                isDragging: false,
                fileInvalid: false,
                fileType: "",
                isUploading: false,
            };

            this.newDraft = {
                description: ""
            }

            this.showCreateDraft = false;
            this.loading.value = false;
        },
        handleClickDraftItem(clickedDraft) {
            console.log("Clicked Draft: ", clickedDraft);
            console.log("Drafts: ", this.drafts);
            // detect if the ctrl key is pressed

            // detect if the ctrl or cmd key is pressed

            if (event.ctrlKey || event.metaKey) {

                console.log("Ctrl Key Pressed: ", event);
                this.draftToPreview = null;
                this.attemptingMultiSelect = true;
                this.iframeUrl = "";

                // if the clicked draft is already selected, remove it from the array
                const indexInMultiSelectedDrafts = this.multiSelectedDrafts.findIndex((draft) => draft.uuid === clickedDraft.uuid);
                if (indexInMultiSelectedDrafts > -1) {
                    this.multiSelectedDrafts.splice(indexInMultiSelectedDrafts, 1);
                    // unset the selected flag on the clicked draft
                    const indexB = this.drafts.findIndex((draft) => draft.uuid === clickedDraft.uuid);
                    this.drafts[indexB].selected = false;
                    return;
                }

                // if multiSelectedDrafts is less than 2, add the draft to the array
                if (this.multiSelectedDrafts.length < 2) {

                    this.multiSelectedDrafts.push(clickedDraft);
                    // set the selected flag on the clicked draft
                    const indexB = this.drafts.findIndex((draft) => draft.uuid === clickedDraft.uuid);
                    this.drafts[indexB].selected = true;
                } else {
                    // if multiSelectedDrafts is greater than 2, remove the first item and add the draft to the array
                    // unset th selected flag on the first item
                    const indexA = this.drafts.findIndex((draft) => draft.uuid === this.multiSelectedDrafts[0].uuid);
                    this.drafts[indexA].selected = false;

                    this.multiSelectedDrafts.shift();
                    this.multiSelectedDrafts.push(clickedDraft);

                    // set the selected flag on the clicked draft
                    const indexB = this.drafts.findIndex((draft) => draft.uuid === clickedDraft.uuid);
                    this.drafts[indexB].selected = true;
                }
            } else {
                // Regular click on a draft -> Show the preview.

                // clear the multi selected drafts, put this one in.
                this.multiSelectedDrafts = [];
                this.multiSelectedDrafts.push(clickedDraft);

                // unset the selected flag on all drafts
                this.drafts.forEach((draft) => {
                    draft.selected = false;
                });

                // find the index of the selected draft in drafts
                let index = this.drafts.findIndex((draft) => draft.uuid === clickedDraft.uuid);
                this.drafts[index].selected = true;

                this.draftToPreview = clickedDraft;
                this.attemptingMultiSelect = false;

                this.previewDraft();
            }
        },
        async previewDraft() {
            if (!this.draftToPreview) return; // Nothing to do!
            console.log("Preview Draft: ", this.draftToPreview);
            this.previewDraftLoading = true;

            try {
                // Get the URL of the file from supabase and set it.
                this.previewDraftUrl = await this.getSignedUrlFromSupabase(this.draftToPreview.storage_location);
            } catch (error) {
                console.log("Preview Error: ", error.message);
            } finally {
                this.previewDraftLoading = false;
            }

            // The preview should show if the required flags and variables are set correctly.
        },
        async compareSelectedDrafts() {
            if (this.multiSelectedDrafts.length !== 2) return; // Nothing to do!

            this.uploadingToDraftable = true;
            try {
                const formData = new FormData();
                formData.append('left.file_type', this.multiSelectedDrafts[0].file_type);
                formData.append('left.source_url', await this.getSignedUrlFromSupabase(this.multiSelectedDrafts[0].storage_location));
                formData.append('right.file_type', this.multiSelectedDrafts[1].file_type);
                formData.append('right.source_url', await this.getSignedUrlFromSupabase(this.multiSelectedDrafts[1].storage_location));
                formData.append('public', true);

                const response = await axios.post('https://api.draftable.com/v1/comparisons', formData, {
                    headers: {
                        Authorization: `Token ${this.authToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log("Response from Draftable: ", response.data);

                const responseData = response.data;
                if (responseData?.identifier) {
                    this.showDiffViewer(responseData.identifier);
                }
            } catch (error) {
                console.error(error);
                // TODO: Clear Selection
            } finally {
                this.uploadingToDraftable = false;
            }
        },
        showDiffViewer(comparisonIdentifier) {
            this.iframeUrl = `${this.viewerUrlBase}/${this.accountId}/${comparisonIdentifier}${this.viewerUrlQuery}`;
            console.log("Iframe URL: ", this.iframeUrl);
        },
        async getSignedUrlFromSupabase(fileStorageLocation) {
            const { data, error } = await supabase.storage.from("documents-01").createSignedUrl(fileStorageLocation, 600);
            if (error) throw new Error(error.message);

            console.log("Signed URL data: ", data, data.signedUrl);

            return data.signedUrl;
        },
    }
}

</script>
<template>
    <div class="bread-crumbs">
        <h1><router-link to="/documents">Document Cabinet</router-link> > Document Manager</h1>
    </div>

    <div class="document-info">
        <div class="document-primary">
            <h1>{{ documentWithDrafts.name }}</h1>
            <p>{{ documentWithDrafts.description }}</p>
        </div>
        <div class="document-meta">
            <div>
                <span class="label">Owner</span>
                <span class="info">{{ documentWithDrafts?.profiles?.username }}</span>
            </div>
            <div>
                <span class="label">Status</span>
                <span class="info">{{ documentWithDrafts.status }}</span>
            </div>
            <div>
                <span class="label">Last Updated</span>
                <span class="info">{{ documentWithDrafts.updated_at }}</span>
            </div>
            <div>
                <span class="label">Created</span>
                <span class="info">{{ documentWithDrafts.created_at }}</span>
            </div>
        </div>
        <div class="document-actions">
            <span class="status-pill">In Progress</span>
            <button class="btn btn-success btn-sm" disabled>Finalize Document</button>
            <span class="action-trigger">Discard Document</span>
        </div>
    </div>

    <div v-if="showCreateDraft" class="create-draft-wrapper">
        <h3>Add a New Draft</h3>
        <form class="create-draft-form" @submit.prevent="addNewDraft()">
            <div class="input-item">
                <label class="form-label">Draft Description</label>
                <input type="text" v-model="newDraft.description" class="form-control" required />
            </div>
            <div class="input-item">
                <label class="form-label">Updated By</label>
                <input type="text" class="form-control" disabled placeholder="You" />
            </div>
            <div class="input-item">
                <div id="new-draft-drop-area" class="new-draft-drop-area"
                    :class="{ 'drag-over': newDraftProcessing.isDragging, 'file-valid': newDraftProcessing.file && !newDraftProcessing.fileInvalid }"
                    @dragenter="handleDragEnter" @dragleave="handleDragLeave" @dragover="handleDragOver"
                    @drop="handleDropNewDraft">
                    <span class="drop-area-child">Drop Document Here (PDF, DOCX or TXT)</span>
                    <h2 class="drop-area-child" v-if="newDraftProcessing.file">
                        <img class="drop-area-child" src="/icons8-happy-file-64.png" />
                        {{ newDraftProcessing.file.name }}
                    </h2>
                    <p class="drop-area-child" v-if="newDraftProcessing.fileType">Type: {{ newDraftProcessing.fileType }}
                    </p>
                    <p class="drop-area-child" v-if="newDraftProcessing.fileInvalid">Invalid File Type</p>
                </div>
            </div>
            <div class="input-item form-actions">
                <button type="submit" class="btn btn-primary" :disabled="loading.value">Create Draft</button>
                <button @click="closeCreateNewDraftDialog" class="btn btn-outline-primary">
                    Close
                </button>
            </div>
        </form>
    </div>


    <div v-if="!showCreateDraft" class="box-content">
        <div class="box-left">
            <div class="drafts-actions">
                <button v-if="!showCreateDraft" @click="showCreateDraft = !showCreateDraft" class="btn btn-primary">
                    Add New Draft
                </button>
                <button v-else @click="showCreateDraft = !showCreateDraft" class="btn btn-outline-primary">
                    Close
                </button>
            </div>
            <div class="drafts-wrapper">
                <div class="draft-list-header">
                    <h4>Document Drafts</h4>
                    <div v-if="drafts.length == 0">No active drafts found.</div>
                    <div v-else><span class="help">To compare, ctrl/cmd + click and select any two versions.</span></div>
                </div>
                <div class="draft-list-wrapper">
                    <div v-for="(draft) in drafts" :key="draft.uuid"
                        :class="['draft-item', draft.selected && attemptingMultiSelect ? 'draft-item-selected' : '', draft.selected && !attemptingMultiSelect ? 'draft-item-preview-active' : '']"
                        @click.prevent="handleClickDraftItem(draft);">
                        <div class="draft-item-content">
                            <div class="left">
                                <img src="/icons8-happy-file-64.png" />
                            </div>
                            <div class="content">
                                <div class="draft-item-description">
                                    <span>{{ draft.description }}</span>
                                </div>
                                <div class="draft-item-meta">
                                    <span>{{ draft.original_file_name }}</span>
                                </div>
                                <div class="draft-item-meta">
                                    <span>{{ draft?.profiles?.username }}</span>
                                    <span>{{ draft.created_at.slice(0, 19).replace('T', ' ') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="draft-item-actions">
                            <span class="action-trigger" @click.prevent="archiveDraft(draft.uuid)">Archive</span>
                            <span class="action-trigger" @click.prevent="deleteDraft(draft.uuid)">Delete</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-main">
            <!-- <WebViewer initialDoc="https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf" /> -->
            <WebViewer v-if="boxMainDisplayState == 'DRAFT_PREVIEW'" :initialDoc="previewDraftUrl" />

            <div class="diff-view-wrapper" v-if="boxMainDisplayState == 'DIFF_VIEW'">
                <iframe :src="iframeUrl" style="width: 100%; border: 1px solid #e0e0e0;" allowfullscreen />
            </div>

            <div v-if="boxMainDisplayState == 'NOTIFICATIONS'" class="notifications">
                <div v-if="drafts.length == 0">Add a draft to get started!</div>
                <div v-if="previewDraftLoading">Loading Preview...</div>
                <div v-if="attemptingMultiSelect && multiSelectedDrafts.length != 2">
                    <h4>Compare Changes Mode</h4>
                    <p>Please select two drafts to compare. Use ctrl/cmd + click.</p>
                </div>
                <div v-if="attemptingMultiSelect && multiSelectedDrafts.length == 2">
                    <h4>Compare Changes Mode</h4>
                    <button @click.prevent="compareSelectedDrafts" class="btn btn-info btn-lg">
                        Compare Selected Drafts
                    </button>
                </div>
            </div>
        </div>

    </div>
    <!-- <div>{{ multiSelectedDrafts }} <br><br> {{ attemptingMultiSelect }} <br><br> {{ draftToPreview }}</div> -->
</template>

<style lang="scss" scoped>
.bread-crumbs {

    h1,
    h1 a {
        color: #888;
        font-size: 18px;
    }
}

.document-info {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 20px;

    .document-primary {
        flex-grow: 1;
        max-width: 35%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
            font-size: 30px;
            margin-bottom: 0;
        }

        p {
            font-size: 16px;
            color: #888;
        }
    }

    .document-meta {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        div {
            .label {
                font-size: 12px;
                color: #888;
                width: 100px;
                display: inline-block;
            }

            .info {
                font-size: 14px;
            }

        }
    }

    .document-actions {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
    }
}

.box-content {
    display: flex;
    flex-direction: row;

    .box-left {
        flex-grow: 1;
        max-width: 450px;
        min-width: 380px;
        background-color: #f2f2f2;
        padding: 1rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .box-main {
        min-width: 800px;
        flex-grow: 4;
        padding: 1rem;
        border: 1px solid #ccc;
        height: 90vh;
        position: relative;

        .notifications {
            text-align: center;
            padding-top: 150px;
            font-size: 20px;
        }

        .diff-view-wrapper {
            height: 100%;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.drafts-actions {
    button {
        width: 100%;
    }
}

.drafts-wrapper {
    .draft-list-header {
        h4 {
            margin: 0px;
        }

        text-align: center;
        margin-bottom: 10px;
    }

    .draft-list-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-right: -1rem;

        .draft-item {
            padding: 1rem;
            border-radius: 10px 0 0 16px;
            background-color: #fff;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid #ccc;

            .draft-item-content {
                display: flex;
                position: relative;

                .left {
                    padding-right: 1rem;

                    img {
                        width: 36px;
                        opacity: 0.35;
                    }
                }

                .content {
                    position: relative;
                    width: 100%;

                    .draft-item-description {
                        font-size: 18px;
                        margin-bottom: 20px;
                    }

                    .draft-item-meta {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        color: #999;
                        margin-bottom: 10px;
                        font-size: 14px;
                        width: 100%;

                        span {
                            display: block;
                        }
                    }
                }
            }

            .draft-item-actions {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                gap: 8px;
                width: calc(100% + 2rem);
                margin-left: -1rem;
                margin-bottom: -1rem;
                opacity: 0;

                .action-trigger {
                    background-color: #ffeccb;
                    display: block;
                    text-align: center;
                    flex-grow: 1;
                    padding: 6px 0px;

                    &:hover {
                        background-color: #ffb25f;
                        text-decoration: none;
                    }
                }
            }
        }

        .draft-item-selected {
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
            background-color: rgb(216, 216, 242) !important;

        }

        .draft-item-preview-active {
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
            background-color: #d3eecb !important;

            .draft-item-actions {
                opacity: 1 !important;
            }
        }
    }
}

.create-draft-wrapper {
    background-color: #f6f6f6;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    max-width: 800px;
    margin: auto;

    .create-draft-form {
        .input-item {
            margin-bottom: 1.5rem;
        }

        .form-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
}

.create-draft-wrapper .new-draft-drop-area {
    width: 100%;
    border: 2px dashed #ccc;
    text-align: center;
    padding: 30px;
    background-color: #ffeccb;
}
</style>