<script>
import { supabase } from "@/supabase.js";
import { store } from "../store"
import { ref } from "vue"
import router from "../router" // this.$router available in methods. Need to import to use in setup()

export default {
    mounted() {
        console.log("Mounted in Profile.");
        this.getProfile();
    },
    setup() {
        const loading = ref(true);
        const username = ref("");
        const website = ref("");
        const avatar_url = ref("");
        let showForm = ref(false);
        let userEmail = ref(store.state?.user?.email || "");

        async function getProfile() {

            console.log("Logged In: ", store.isLoggedIn());
            console.log("Logged In User: ", store.state.user);

            if (store.isLoggedIn()) {
                try {
                    loading.value = true;
                    const user = store.state.user;

                    // Get from supabase
                    let { data, error, status } = await supabase
                        .from("profiles")
                        .select(`username, website, avatar_url`)
                        .eq("id", user.id)
                        .single()

                    if (error && status !== 406) throw error

                    if (data) {
                        username.value = data.username;
                        website.value = data.website;
                        avatar_url.value = data.avatar_url;
                        this.showForm = true;
                    }
                } catch (error) {
                    console.log("Could not access DB: ", error.message);
                } finally {
                    loading.value = false;
                }
            }
        }

        async function updateProfile() {
            try {
                loading.value = true;

                const user = store.state.user;
                const updates = {
                    id: user.id,
                    username: username.value,
                    website: website.value,
                    avatar_url: avatar_url.value,
                    updated_at: new Date(),
                }

                let { error } = await supabase.from("profiles").upsert(updates);

                if (error) throw error;
            } catch (error) {
                console.log("Could Not Update Profile: ", error.message);
                alert(error.message);
            } finally {
                loading.value = false;
            }
        }

        async function signOut() {
            try {
                loading.value = true;
                let { error } = await supabase.auth.signOut();
                if (error) throw error;
                store.state.user = null;
                router.push({ name: 'Auth' });
            } catch (error) {
                console.log("Could Not Sign Out: ", error.message);
                alert(error.message);
            } finally {
                loading.value = false;
            }
        }

        return {
            loading,
            username,
            website,
            avatar_url,
            getProfile,
            updateProfile,
            showForm,
            userEmail,
            signOut
        }
    }
}
</script>

<template>
    <div id="box-profile">
        <div v-if="showForm">
            <form class="form-widget" @submit.prevent="updateProfile">
                <div class="input-item">
                    <label for="email">Email</label><input class="input-text" id="email" type="text" :value="userEmail"
                        disabled />
                </div>
                <div class="input-item">
                    <label for="username">Name</label><input class="input-text" id="username" type="text"
                        v-model="username" />
                </div>
                <div class="input-item">
                    <label for="website">Website</label><input class="input-text" id="website" type="website"
                        v-model="website" />
                </div>
                <div>
                    <input type="submit" class="btn btn-primary" :value="loading ? 'Loading ...' : 'Update Information'"
                        :disabled="loading" />
                </div>
            </form>
            <hr>
            <div>
                <button class="btn btn-danger" @click.prevent="signOut" :disabled="loading">
                    Sign Out
                </button>
            </div>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<style lang="scss" scoped>
// Duplicated in Auth.vue
#box-profile {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
}

form div {
    margin-bottom: 1rem;
}

.input-item {
    width: 100%;
    position: relative;

    label {
        display: block;
        margin-bottom: 0.2rem;
    }

    .input-text {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 18px;
        width: 100%;
    }
}
</style>